class hcpv_special {

    constructor(specialList) {

        this.specialList = specialList;
        this.specialObj = {};

        for (var i = 0; i < specialList.length; i++) {
            this.specialObj[specialList[i]["@id"]] = specialList[i];
        }

    }

    // Return a special with matching id
    getSpecialById(id) {

        for (var i = 0; i < this.specialList.length; i++) {

            if (this.specialList[i]["@id"] == id) return this.specialList[i];
        }

        return null;
    }

    // Return a special with matching id  
    getSpecialById2(id) {
        return this.specialObj[id["@id"]];
    }

     getSpecialAPRTXTById2(id) {
        return this.specialObj[id["@id"]].SpecialName;
    }



    

}